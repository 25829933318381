<template>
    <a-card :bordered="false" class="card-area">
        <div class="search"><!-- 搜索区域 -->
            <div class="items" style="flex: none">
                <div class="one-item">
                    <a-select v-model="value" placeholder="选择过滤字段" style="width:100%">
                        <a-select-option value="订单号">订单号</a-select-option>
                        <a-select-option value="用户">用户</a-select-option>
                        <a-select-option value="付款状态">付款状态</a-select-option>
                    </a-select>
                </div>
                <div class="one-item">
                    <a-input placeholder="请输入筛选条件"></a-input>
                </div>
            </div>
            <div class="operator">
                <a-button type="primary" @click="btsearch">查询</a-button>
            </div>
        </div>
        <a-table :dataSource="tableData" rowKey="id" size="small" :bordered="true" :columns="columns"
            :scroll="{ x: 1000 }">
            <template slot="imghead" slot-scope="text">
                <div v-if="text.uhead" class="img-list">
                    <el-image style="width: 40px; height: 40px" :src="(text.uhead || '') | fullImgPath" fit="contain"
                        :preview-src-list="(text.uhead || '').split(',')" />
                </div>
            </template>
            <template slot="status" slot-scope="text,record">
                <a-tag :color="record.status | dictName('payRequestStatus', 'color')">
                    {{ record.status | dictName("payRequestStatus") }}
                </a-tag>
            </template>
        </a-table>
    </a-card>
</template>
<script>
import {DICT} from "@/utils/dict";
import { message } from 'ant-design-vue';
export default {
    name: "readerList",
    data() {
        return {
            tableData: [],
            value: ''
        };
    },
    created() {
        this.searchTable();
    },
    computed: {
        payRequestStatus() {
            return DICT['payRequestStatus']
        },
        columns() {
            return [{
                title: "ID",
                width: 50,
                align: "center",
                dataIndex: "id",
            }, {
                title: "用户",
                width: 80,
                align: "center",
                dataIndex: "username",
            }, {
                title: "头像",
                width: 50,
                align: "center",
                //dataIndex: "uhead",
                scopedSlots: { customRender: "imghead" },
            }, {
                title: "付款状态",
                width: 80,
                align: "center",
                dataIndex: "status",
                scopedSlots: { customRender: "status" },
            }, {
                title: "订单号",
                width: 120,
                align: "center",
                dataIndex: "orderno",
            }, {
                title: "金额",
                width: 80,
                align: "center",
                dataIndex: "amount",
            }, {
                title: "创建日期",
                width: 80,
                align: "center",
                dataIndex: "createdate",
            }, {
                title: "微信支付单号",
                width: 160,
                align: "center",
                dataIndex: "transactionid",
            }, {
                title: "更新时间",
                width: 80,
                align: "center",
                dataIndex: "modifytime",
            }, {
                title: "服务状态",
                width: 80,
                align: "center",
                dataIndex: "fstatus",
            }, {
                title: "需求说明",
                width: 120,
                align: "center",
                dataIndex: "mark",
            }, {
                title: "有效期",
                width: 80,
                align: "center",
                dataIndex: "vdate",
            }];
        }
    },
    methods: {
        btsearch() {
            message.error('查询功能待开发');
        },
        searchTable() {
            let that = this;
            that.showLoading = true;
            that.$post("reader/readerTable", { "fwhere": "2" }).then((res) => {
                that.showLoading = false;
                that.tableData = res.data;
            });
        }
    }
}
</script>
<style>
.el-row {
    margin-bottom: 20px;
}

.el-col {
    border-radius: 4px;
}

.bg-purple-dark {
    background: #99a9bf;
}

.bg-purple {
    background: #d3dce6;
}

.bg-purple-light {
    background: #e5e9f2;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}
</style>
