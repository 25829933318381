<template>
    <a-modal :title="title" :visible="visible" :confirm-loading="confirmLoading" :width="800" @ok="handleOk"
        @cancel="visible = false">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" v-if="visible">
            <a-form-model-item label="名称" prop="fname" v-bind="formItemLayout">
                <a-input v-model="form.fname" allow-clear placeholder="输入名称" />
            </a-form-model-item>
            <a-form-model-item label="类型" prop="ftype" v-bind="formItemLayout">
                <a-select v-model="form.ftype" placeholder="选择类型" style="width:100%">
                    <a-select-option v-for="item in $DICT['keywordStatus']" :value="item.value" :key="item.value">{{
                        item.label }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="是否有效" v-bind="formItemLayout" prop="status">
                <a-radio-group v-model="form.status" v-decorator="['status',
                    { initialValue: 1 }]">
                    <a-radio :value="1">有效</a-radio>
                    <a-radio :value="0">无效</a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="排序号" v-bind="formItemLayout" prop="fseq">
                <a-input-number v-model="form.fseq" :step="10" :min="10" v-decorator="['fseq',
                    { initialValue: 10 }]" />
                <span style="font-size:0.8em;color:#999">数字越大排序越前！</span>
            </a-form-model-item>
            <a-form-model-item label="说明" v-bind="formItemLayout" prop="mark">
                <a-textarea v-model="form.mark" v-decorator="['mark']" style="min-height:100px;" />
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>
<script>
export default {
    name: "modifyKeywordModal",
    data() {
        return {
            formItemLayout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 15 },
            },
            visible: false,
            mode: 'edit',
            title: "",
            confirmLoading: false,
            form: {},
            rules: {
                fname: [
                    {
                        required: true,
                        message: "请输入名称",
                        trigger: "blur",
                    },
                ],
                ftype: [
                    {
                        required: true,
                        message: "请选择类型",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    computed: {
        headers() {
            return {
                Authentication: this.$store.state.account.token,
            };
        },
    },
    methods: {
        showModal(record, mode) {
            const that = this;

            this.visible = true;
            this.mode = mode || 'new'
            if (this.mode == 'new') {
                this.title = "创建关键词";
                this.form = { status: 1, fseq: 10, parentid: 0 };
            } else {
                this.title = "修改关键词";
                this.form = {
                    ...record,
                };
            }
        },
        handleOk() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.confirmLoading = true;
                    console.log(this.form);
                    this.$postJson("reader/keywordAdd", this.form).then((res) => {
                        this.visible = false;
                        this.confirmLoading = false;
                        this.$message.success(res.message);
                        this.$emit("success");
                    });
                } else {
                    this.confirmLoading = false;
                }
            });
        }
    }
}
</script>
<style lang="less" scoped>
.ant-input-number {
    width: 100% !important;
}
</style>
