// 字典数据
export const DICT = {
    // 页面模式
    validStatus: [
        {value: 1, label: "有效", color: "green"},
        {value: 0, label: "无效", color: "pink"},
    ],
    isTop: [
        {value: "0", label: "否", color: "pink"},
        {value: "1", label: "是", color: "green"},
    ],
    yesNo: [
        {value: "0", label: "否", color: "pink"},
        {value: "1", label: "是", color: "green"},
    ],

    isPublic: [
        {value: "0", label: "否", color: "pink"},
        {value: "1", label: "是", color: "green"},
    ],
    isShow: [
        {value: "0", label: "永久隐藏", color: "pink"},
        {value: "1", label: "正常显示", color: "green"},
        {value: "-1", label: "隐藏7天", color: "#FF9900"},
        {value: "-2", label: "隐藏15天", color: "#FF9900"},
    ],
    postType: [//1-普通，2--审核专用，5-曝光公司，10--法援专区，
        {value: "1", label: "普通帖子", color: "pink"},
        {value: "2", label: "审核专用", color: "green"},
        {value: "5", label: "曝光公司", color: "#FF9900"},
        {value: "6", label: "社区帖子", color: "blue"},
        {value: "10", label: "法援专区", color: "red"},
        {value: "11", label: "面经帖子", color: "cyan"},
        {value: "12", label: "招聘内推", color: "blue"},
        {value: "13", label: "意见建议", color: "#5794f8"},
        {value: "14", label: "知识库", color: "red"},
        {value: "15", label: "匿名真心话", color: "cyan"},
        {value: "16", label: "老板信箱", color: "blue"},
        {value: "17", label: "点评房东", color: "red"},
        {value: "18", label: "消费投诉", color: "cyan"},
        {value: "19", label: "曝薪资", color: "blue"},
        {value: "20", label: "问公司", color: "red"},
        {value: "21", label: "聊职业", color: "cyan"},
        {value: "22", label: "聊行业", color: "blue"},
        {value: "23", label: "出主意", color: "blue"},
        {value: "24", label: "分享心情", color: "blue"},
        {value: "25", label: "点评领导同事", color: "blue"},
        {value: "26", label: "下载资源", color: "blue"},
        {value: "27", label: "猎头招聘", color: "blue"},
        {value: "28", label: "猎头资料", color: "blue"},
        {value: "29", label: "职场口碑", color: "blue"},
        {value: "30", label: "云队友", color: "blue"},
        {value: "31", label: "互帮互助", color: "blue"},
        {value: "32", label: "工作经验", color: "blue"},
    ],
    postLogActStatus: [
        {value: "0", label: "待后续处理", color: "pink"},
        {value: "1", label: "已完成处理", color: "green"},
        {value: "-1", label: "已失效", color: "gray"},
    ],
    postStatus: [
        {value: "0", label: "待审核", color: "rgb(24, 144, 255)"},
        {value: "1", label: "已审核", color: "green"},
        {value: "-1", label: "未通过", color: "red"},
        {value: "-2", label: "已失效", color: "red"},
    ],
    outlinkPageMode: [
        {value: 0, label: "无", color: "#FF9900"},
        {value: 2, label: "跳转模式", color: "blue"},
        {value: 1, label: "弹窗模式", color: "green"},
        {value: 3, label: "加好友模式", color: "red"},
    ],
    // 页面模式
    userAccountType: [
        {value: 0, label: "普通", color: "green"},
        {value: 1, label: "官方", color: "#FF9900"},
        {value: 2, label: "虚拟", color: "cyan"},
    ],
    wifiCashStatus: [
        {value: "0", label: "待审核", color: "#FF9900"},
        {value: "10", label: "已审核", color: "green"},
        {value: "20", label: "已付款", color: "blue"},
        {value: "-10", label: "审核未通过", color: "red"},
    ],
    debtStatus: [
        {value: "0", label: "正常", color: "green"},
        {value: "1", label: "已撤回", color: "#FF9900"},
    ],
    // 用户认证状态
    authStatus: [
        {value: "0", label: "新人", color: "pink"},
        {value: "1", label: "待认证", color: "rgb(24, 144, 255)"},
        {value: "2", label: "已认证", color: "green"},
        {value: "-1", label: "未通过", color: "red"},
    ],
    // 建议与投诉
    feedbackTypeStatus: [
        {value: "0", label: "反馈", color: "green"},
        {value: "1", label: "争议", color: "#ff9900"},
        {value: "2", label: "举报", color: "#ff0000"},
    ],
    // 用户协助认证权限  0--不允许协助， 1--允许协助
    helpStatus: [
        {value: "0", label: "不允许", color: "red"},
        {value: "1", label: "允许", color: "#ff9900"},
    ],

    authPassStatus: [
        {value: "0", label: "新建", color: "pink"},
        {value: "1", label: "已通过", color: "green"},
        {value: "-1", label: "已驳回", color: "#ff9900"},
    ],
    regFrom: [
        {value: "1", label: "微信", color: "pink"},
        {value: "2", label: "字节", color: "green"}
    ],

    readStatus: [
        {value: "0", label: "未读", color: "pink"},
        {value: "1", label: "已读", color: "green"},
    ],
    fansUserType: [
        {value: "1", label: "普通", color: "pink"},
        {value: "2", label: "律师", color: "green"},
    ],
    taskMode: [
        {value: "0", label: "普通任务", color: "cyan"},
        {value: "1", label: "每日任务", color: "pink"},
        {value: "2", label: "成长任务", color: "green"},
    ],
    userNoticeMode: [
        {value: "0", label: "系统消息", color: "pink"},
        {value: "1", label: "客服消息", color: "green"},
        {value: "2", label: "审核消息", color: "red"}],
    clientType: [
        {value: "1", label: "微信小程序", color: "green"},
        {value: "2", label: "字节小程序", color: "red"}],
    commentMode: [
        {value: "0", label: "普通评论", color: "green"},
        {value: "1", label: "律师建议", color: "pink"},
        {value: "2", label: "法援总结", color: "#FF9900"}],
    payRequestStatus: [
        // 0-，1-预支付，2-成功，3-失败，4-超时取消，5-失效
        {value: "0", label: "新建", color: "red"},
        {value: "1", label: "预支付", color: "pink"},
        {value: "2", label: "成功", color: "green"},
        {value: "3", label: "失败", color: "#FF9900"},
        {value: "4", label: "超时取消", color: "#FF9900"},
        {value: "5", label: "失效", color: "#FF9900"}],
    dimType: [
        {value: "1", label: "综合", color: "#FF9900"},
        {value: "2", label: "员工", color: "pink"},
        {value: "3", label: "合作商", color: "red"},
        {value: "4", label: "客户", color: "cyan"},
        {value: "5", label: "同行", color: "green"}],
    categoryType: [
        {value: "1", label: "圈子分类", color: "#FF9900"},
        {value: "2", label: "手册分类", color: "pink"},
        {value: "3", label: "评测分类", color: "green"},
        {value: "4", label: "省钱宝分类", color: "cyan"},
        {value: "5", label: "商品分类", color: "blue"},
        {value: "6", label: "行业分类", color: "red"},
        {value: "7", label: "职业分类", color: "blue"},
        {value: "8", label: "下载资源分类", color: "blue"},
        {value: "9", label: "工作福利分类", color: "blue"},
        {value: "10", label: "技能分类", color: "blue"},
        {value: "11", label: "律师擅长领域", color: "blue"},
        {value: "12", label: "律师业务类型", color: "blue"},
    ],
    evaluationResultType: [
        {value: "1", label: "单一结果", color: "#FF9900"},
        {value: "2", label: "关联结果", color: "pink"}],
    cheaperWordType: [
        {value: "1", label: "平替词", color: "#FF9900"},
        {value: "2", label: "代工厂", color: "pink"}]
    ,
    //---------流量卡销售----------
    cardOrderSendStatus: [
        {value: "0", label: "新建", color: "cyan"},
        {value: "1", label: "登记成功", color: "green"},
        {value: "-1", label: "登记失败", color: "#FF9900"}]
    ,
    cardOrderKbsStatus: [
        //订单主状态 -1：待下单, 1：已下单, 0：已作废, 2：已发货, 3：已激活, 4：已充值,5,线下送货， 10：已结算
        {value: "-1", label: "待下单", color: "#FF9900"},
        {value: "1", label: "已下单", color: "green"},
        {value: "0", label: "已作废", color: "pink"},
        {value: "2", label: "已发货", color: "cyan"},
        {value: "3", label: "已激活", color: "green"},
        {value: "4", label: "已充值", color: "green"},
        {value: "5", label: "线下送货", color: "blue"},
        {value: "10", label: "已结算", color: "green"},
    ],
    cleanTokenType: [
        {value: "1", label: "清理模式", color: "#FF9900"},
        {value: "2", label: "朋友圈屏蔽", color: "green"},
        {value: "3", label: "屏蔽+清理", color: "pink"},
    ],
    cleanTokenStatus: [
        {value: "-1", label: "已失效", color: "#FF9900"},
        {value: "1", label: "待分配", color: "green"},
        {value: "2", label: "已分配", color: "pink"},
    ],
    cleanOrderAssignStatus: [
        {value: "-1", label: "分配失败", color: "#FF9900"},
        {value: "1", label: "已分配", color: "green"},
        {value: "0", label: "待分配", color: "pink"},
    ],
    orderPayStatus: [
        //状态（0--新建，1--预支付，2--支付成功，-1--支付失败
        {value: "-1", label: "支付失败", color: "#FF9900"},
        {value: "0", label: "新建", color: "pink"},
        {value: "1", label: "预支付", color: "pink"},
        {value: "2", label: "支付成功", color: "green"},
    ],
    importPostStatus: [
        {value: "0", label: "未导入", color: "pink"},
        {value: "1", label: "已导入", color: "green"},
        {value: "-1", label: "已失效", color: "#FF9900"},
    ],
    goodsPlatform: [ //平台类型（1--自由商品，2--拼多多，3--京东，4--唯品会，5--淘宝...)
        {value: 1, label: "自有", color: "pink"},
        {value: 2, label: "拼多多", color: "#FF9900"},
        {value: 3, label: "京东", color: "green"},
        {value: 4, label: "唯品会", color: "blue"},
        // {value: "5", label: "淘宝", color: "cyan"},
    ],
    bannerAdType: [ //广告类型（1--小程序平台广告-腾讯或字节，2---饿了么广告，3--商诚小程序广告）
        {value: 1, label: "腾讯/字节广告", color: "pink"},
        {value: 2, label: "饿了么广告", color: "#FF9900"},
        {value: 3, label: "商诚小程序商品", color: "green"},
    ],

    commentType: [
        {value: 1, label: "评论帖子", color: "green"},
        {value: 2, label: "回复评论", color: "cyan"},
    ],
    autoPrizeType: [
        {value: 1, label: "帖子自动点赞", color: "green"},
        {value: 2, label: "帖子自动评论", color: "cyan"},
        {value: 3, label: "评论自动点赞", color: "pink"},
        {value: 4, label: "评论自动评论", color: "#FF9900"},
    ],
    qrCodeMode: [
        {value: 1, label: "法律援助", color: "green"},
        {value: 2, label: "互助群聊", color: "cyan"},
    ],
    careerType: [
        {value: 1, label: "行业", color: "red"},
        {value: 2, label: "岗位", color: "cyan"},
        {value: 3, label: "职位", color: "pink"},
    ],
    payRequestType: [//支付类型（1-支付赞赏，2-观看广告，3--支付匿名消息费用，4--清粉,5--流量卡，6--支付VIP费用，7--支付背调费用，8--付费下载，9--付费阅读）
        {value: 1, label: "支付赞赏", color: "cyan"},
        {value: 2, label: "观看广告", color: "green"},
        {value: 3, label: "匿名消息", color: "green"},
        {value: 4, label: "清粉订单", color: "red"},
        {value: 5, label: "流量卡", color: "green"},
        {value: 6, label: "支付VIP费用", color: "#2979ff"},
        {value: 7, label: "背调订单", color: "#FF9900"},
        {value: 8, label: "付费下载", color: "#19be6b"},
        {value: 9, label: "付费阅读", color: "green"},
        {value: 10, label: "技能订单", color: "green"},
        {value: 11, label: "知情人订单", color: "blue"},
    ],
    payLinkType: [//关联类型（1--帖子，2--下载资源，3.背调订单）
        {value: 1, label: "帖子", color: "red"},
        {value: 2, label: "下载资源", color: "cyan"},
        {value: 3, label: "背调订单", color: "green"},
        {value: 4, label: "技能订单", color: "green"},
    ],
    backtrackStatus: [
        {value: 0, label: "新建", color: "red"},
        {value: 1, label: "待支付", color: "red"},
        {value: 2, label: "背调中", color: "green"},
        {value: 3, label: "已完成", color: "cyan"},
        {value: -1, label: "已拒绝", color: "#FF9900"},
    ],
    backtrackPayStatus: [
        {value: 0, label: "未支付", color: "#FF9900"},
        {value: 1, label: "已支付", color: "green"},
        {value: 2, label: "VIP免费", color: "#2979ff"},
        {value: 3, label: "已退款", color: "red"},
    ],
    backtrackMode: [
        {value: 1, label: "免费订单", color: "green"},
        {value: 2, label: "优惠优惠", color: "cyan"},
        {value: 3, label: "普通订单", color: "pink"},
    ],

    resourcePayMode: [
        {value: 0, label: "免费", color: "green"},
        {value: 1, label: "付费", color: "cyan"},
        {value: 2, label: "看广告", color: "pink"},
    ],

    resourceTargetMode: [
        {value: 1, label: "付费资源文件", color: "cyan"},
        {value: 2, label: "付费文本内容", color: "pink"},
    ],
    resourceType: [
        {value: 0, label: "普通资源", color: "green"},
        {value: 1, label: "仲裁案例", color: "cyan"},
        {value: 2, label: "法律文书", color: "red"},
    ],
    keywordStatus: [
        {value: 1, label: "情报关键词", color: "green"},
        {value: 2, label: "高光时刻", color: "blue"},
    ],
    inagencyNStatus: [
        {value: 1, label: "服务中", color: "green"},
        {value: 2, label: "已完成", color: "blue"},
        {value: 3, label: "过期", color: "red"},
        {value: 4, label: "取消", color: "cyan"},
    ],
    rewardBriefItems:[
        {value: "balance", label: "目前结余金额（元）", color: "green"},
        {value: "out", label: "总支出", color: "cyan"},
        {value: "in", label: "总收入", color: "red"},
        {value: "expireTime", label: "到期时间", color: "green"},
        {value: "rewardTimes", label: "赞助次数", color: "cyan"},
    ]
};
